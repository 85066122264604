var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "custom-table" },
        [
          !_vm.disabled
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.open },
                    },
                    [_vm._v("新增行")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", type: "text" },
                      slot: "reference",
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "el-icon-delete",
                          staticStyle: { color: "#f5222d" },
                          on: { click: _vm.clearAll },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                border: "",
                "show-overflow": "",
                resizable: "",
                data: _vm.detailsTable,
                "radio-config": { strict: false },
                align: "center",
                "max-height": "400",
              },
            },
            [
              _vm.urlParam === "ExternalEmployees"
                ? _c("vxe-table-column", {
                    attrs: { width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("span", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#F56C6C",
                                      "margin-right": "4px",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("变更类型"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: scope.row.sfaCheckTypeDesc,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "sfaCheckTypeDesc", $$v)
                                  },
                                  expression: "scope.row.sfaCheckTypeDesc",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2956279056
                    ),
                  })
                : _vm._e(),
              _vm._l(_vm.columns, function (item, index) {
                return _c("vxe-table-column", {
                  key: index,
                  attrs: {
                    field: item.field,
                    title: item.label,
                    width: item.width || 200,
                  },
                  scopedSlots: _vm._u(
                    [
                      item.required
                        ? {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#F56C6C",
                                        "margin-right": "4px",
                                      },
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(_vm._s(item.label)),
                                ]),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {},
                              [
                                item.type === "input"
                                  ? _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入" + item.label,
                                        disabled: _vm.isDisabled(item, scope),
                                        maxlength: item.max || 9999,
                                      },
                                      on: { change: _vm.changeValue },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]",
                                      },
                                    })
                                  : _vm._e(),
                                item.type === "inputNumber"
                                  ? _c("el-input-number", {
                                      attrs: {
                                        controls: item.controls,
                                        placeholder: "请输入" + item.label,
                                        disabled: _vm.isDisabled(item, scope),
                                        min: 0,
                                        precision: item.precision || 0,
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.detailInputChange(
                                            val,
                                            scope,
                                            item.field
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]",
                                      },
                                    })
                                  : _vm._e(),
                                item.type === "select"
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: item.multiple,
                                          "collapse-tags": item.collapseTags,
                                          placeholder: "请选择" + item.label,
                                          "remote-method": function (name) {
                                            _vm.getSeleteOption(
                                              _vm.val,
                                              item.field,
                                              scope,
                                              name
                                            )
                                          },
                                          disabled: _vm.isDisabled(item, scope),
                                          clearable: "",
                                          filterable: "",
                                          remote: "",
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.selectChange(
                                              val,
                                              item.field,
                                              scope
                                            )
                                          },
                                          focus: function (val) {
                                            return _vm.getSeleteOption(
                                              val,
                                              item.field,
                                              scope,
                                              ""
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row[item.field],
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, item.field, $$v)
                                          },
                                          expression: "scope.row[item.field]",
                                        },
                                      },
                                      _vm._l(
                                        scope.row[item.field + "List"],
                                        function (op, key) {
                                          return _c("el-option", {
                                            key: key,
                                            attrs: {
                                              label: op.label,
                                              value: op.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                item.type === "DatePicker"
                                  ? _c("el-date-picker", {
                                      attrs: {
                                        placeholder: "请选择日期",
                                        "default-time": item.defaultTime,
                                        disabled: _vm.isDisabled(item, scope),
                                        type: "datetime",
                                        format: "yyyy-MM-dd HH:mm:ss",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                      },
                                      on: { change: _vm.changeValue },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]",
                                      },
                                    })
                                  : _vm._e(),
                                item.type === "customer"
                                  ? _c(
                                      "div",
                                      { staticClass: "customer" },
                                      [
                                        scope.row.ext13
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content: scope.row.ext13,
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "customer-text",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openModal(
                                                          scope
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          scope.row.ext13
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "open-modal",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openModal(scope)
                                                  },
                                                },
                                              },
                                              [_vm._v(" 请选择所属经销商 ")]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.formRole !== "update2"
                ? _c("vxe-table-column", {
                    attrs: {
                      title: "操作",
                      width: "150",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "setting-btn" },
                                [
                                  scope.row.imageDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "colorBlue",
                                              staticStyle: {
                                                "margin-right": "20px",
                                                "font-style": "normal",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openAnnex(scope)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.code === "add"
                                                    ? "上传附件"
                                                    : "查看附件"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.disabled
                                    ? _c(
                                        "Popconfirm",
                                        {
                                          attrs: {
                                            title: "此操作将永久删除该数据？",
                                            "confirm-button-type": "text",
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.removeRow(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "text",
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                staticStyle: {
                                                  color: "#f5222d",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      655433937
                    ),
                  })
                : _vm._e(),
              _vm.formRole === "update2"
                ? _c("vxe-table-column", {
                    attrs: {
                      title: "操作",
                      width: "200",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "setting-btn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      scope.row.imageDisabled
                                        ? _c(
                                            "i",
                                            {
                                              staticClass: "colorBlue",
                                              staticStyle: {
                                                "margin-right": "20px",
                                                "font-style": "normal",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openAnnex(scope)
                                                },
                                              },
                                            },
                                            [_vm._v("查看附件")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "i",
                                        {
                                          class:
                                            scope.row.checkStatus !== "PASS"
                                              ? "colorBlue"
                                              : "colorGray",
                                          staticStyle: {
                                            "margin-right": "20px",
                                            "font-style": "normal",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(
                                                scope.row,
                                                "PASS"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("通过")]
                                      ),
                                      _c(
                                        "i",
                                        {
                                          class:
                                            scope.row.checkStatus !== "REJECT"
                                              ? "colorRed"
                                              : "colorGray",
                                          staticStyle: {
                                            "font-style": "normal",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(
                                                scope.row,
                                                "REJECT"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("驳回")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3447068004
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "SelectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "Modal",
        {
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: "modalForm",
            tag: "component",
            attrs: { formConfig: _vm.formConfig },
            on: {
              getResourceList: _vm.getResourceList,
              onClose: _vm.closeModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }