import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import PersonnelAddTable from '../components/personnel_add_table.vue';

formCreate.component('PersonnelAddForm', PersonnelAddTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230313000000002',
      formFunctionCode: 'external_employees_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        // submit: false,
        submitAudit: this.formConfig.code !== 'view',
      },
      formRole: null,
      genderList: [],
      enableStatusList: [],
      urlParam: null,
    };
  },
  async created() {
    this.getDicts();
    if (this.formConfig && this.formConfig.row) {
      console.log(this.formConfig.row.formRole);
      this.formRole = this.formConfig.row.formRole ? this.formConfig.row.formRole : '';
      this.urlParam = this.formConfig.row.urlParam ? this.formConfig.row.urlParam : '';
    }
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'personnelList') {
        v.props = {
          ...v.props,
          formRole: this.formRole,
          code: this.formConfig.code,
          urlParam: this.urlParam,
        };
        v.value = [];
      }
      return v;
    },
    // 批量获取数据字典
    getDicts() {
      request.post('/mdm/mdmdictdata/batchDictSelect',
        ['gender', 'enable_status']).then((res) => {
        if (res.success) {
          const { result } = res;
          this.genderList = (result.gender || []).map((a) => ({ label: a.dictValue, value: a.dictCode }));
          this.enableStatusList = (result.enable_status || []).map((a) => ({ label: a.dictValue, value: a.dictCode }));
        }
      });
    },
    // 表单渲染完成后回调
    formComplete() {
      console.log(this.formConfig);
      if (this.formConfig.row.formRole === 'update2' || this.formConfig.row.urlParam === 'ExternalEmployees') {
        request
          .post('/sfa/sfaMdmUserController/pageList', {
            formNo: this.formConfig.row.id || '',
            isPersonnel: this.formConfig.row.formRole === 'update2' || this.formConfig.row.formRole === 'view1',
          })
          .then((res) => {
            if (res.success) {
              const data = {};
              data.personnelList = (res.result.data || []).map((v) => {
                const obj = {};
                const genderName = (this.genderList.find((m) => m.value === v.gender) || {}).label || '保密';
                const enableStatusName = this.enableStatusList.find((m) => m.value === v.enableStatus).label;
                obj.fullName = v.fullName;
                obj.identityCardNumber = v.identityCardNumber;
                obj.ext12 = v.ext12;
                obj.ext13 = v.ext13;
                obj.userPhone = v.userPhone;
                obj.gender = v.gender || '0';
                obj.genderName = genderName;
                obj.genderList = this.genderList;
                obj.enableStatus = v.enableStatus;
                obj.enableStatusName = enableStatusName;
                obj.enableStatusList = this.enableStatusList;
                obj.startTime = v.startTime;
                obj.endTime = v.endTime;
                obj.userName = v.parentFullName ? v.userName : '';
                // obj.unionName = v.unionName;
                obj.parentFullName = v.parentFullName;
                // obj.userNameList = v.parentFullName ? [{ label: v.unionName, value: v.parentCode }] : [];
                obj.positionLevelCode = v.positionLevelCode;
                obj.positionLevelName = v.positionLevelName;
                obj.positionLevelCodeList = [{ label: v.positionLevelName, value: v.positionLevelCode }];
                obj.parentCode = v.parentPositionCode;
                obj.parentName = v.parentPositionName;
                obj.orgCode = v.orgCode;
                obj.id = v.id;
                obj.processCode = this.formConfig.row.id;
                obj.imageDisabled = v.positionLevelCode !== 'ZWJB000283';
                obj.sfaAttachUrls = v.sfaAttachUrls;
                obj.sfaCheckTypeDesc = v.sfaCheckTypeDesc;
                obj.checkStatus = v.checkStatus;
                return obj;
              });
              this.setValue(data);
            }
          });
      } else if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/sfa/sfaMdmUserController/detail', {
            id: this.formConfig.row.id || '',
          })
          .then((res) => {
            if (res.success) {
              const data = {};
              const genderName = (this.genderList.find((m) => m.value === res.result.gender) || {}).label || '保密';
              const enableStatusName = this.enableStatusList.find((m) => m.value === res.result.enableStatus).label;
              data.personnelList = [{
                fullName: res.result.fullName,
                identityCardNumber: res.result.identityCardNumber,
                ext12: res.result.ext12,
                ext13: res.result.ext13,
                userPhone: res.result.userPhone,
                gender: res.result.gender || '0',
                genderName,
                genderList: this.genderList,
                enableStatus: res.result.enableStatus,
                enableStatusName,
                enableStatusList: this.enableStatusList,
                startTime: res.result.startTime,
                endTime: res.result.endTime,
                userName: this.formConfig.row.parentFullName ? (res.result.positionList[0] || []).userName : '',
                unionName: (res.result.positionList[0] || []).unionName || '',
                parentFullName: (res.result.positionList[0] || []).fullName || '',
                // userNameList: this.formConfig.row.parentFullName ? [{ label: (res.result.positionList[0] || []).unionName, value: (res.result.positionList[0] || []).userName }] : [],
                positionLevelCode: (res.result.positionList[0] || []).positionLevelCode || '',
                positionLevelName: (res.result.positionList[0] || []).positionLevelName || '',
                positionLevelCodeList: [{ label: (res.result.positionList[0] || []).positionLevelName, value: (res.result.positionList[0] || []).positionLevelCode }],
                parentCode: (res.result.positionList[0] || []).parentCode,
                parentName: (res.result.positionList[0] || []).parentName,
                orgCode: (res.result.positionList[0] || []).orgCode,
                imageDisabled: (res.result.positionList[0] || []).positionLevelCode !== 'ZWJB000283',
                sfaAttachUrls: res.result.sfaAttachUrls,
                id: res.result.id,
                sfaCheckTypeDesc: res.result.sfaCheckTypeDesc,
              }];
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      console.log(formData);
      const reg = /^\d{11}$/;
      const isReg = formData.personnelList.find((v) => !reg.test(v.userPhone));
      if (isReg) {
        this.$message.error('请输入11位纯数字手机号');
        return;
      }
      formData.personnelList = formData.personnelList.map((m) => {
        const obj = m;
        delete obj.genderList;
        delete obj.customerList;
        delete obj.userNameList;
        delete obj.parentCodeList;
        delete obj.positionLevelCodeList;
        delete obj.enableStatusList;
        obj.positionList = [
          {
            userName: obj.userName,
            unionName: obj.unionName,
            fullName: obj.parentFullName,
            parentCode: obj.parentCode,
            parentName: obj.parentName,
            orgCode: obj.orgCode,
            positionLevelCode: obj.positionLevelCode,
            positionLevelName: obj.positionLevelName,
            positionCode: this.formConfig.row.positionCode ? this.formConfig.row.positionCode : '',
            positionName: this.formConfig.row.positionName ? this.formConfig.row.positionName : '',
          },
        ];
        obj.id = this.formConfig.row.id || '';
        delete obj.userName;
        delete obj.unionName;
        delete obj.parentFullName;
        delete obj.parentCode;
        delete obj.parentName;
        delete obj.orgCode;
        delete obj.positionLevelCode;
        delete obj.positionLevelName;
        return obj;
      });
      if (formData) {
        let url = '/sfa/sfaMdmUserController/saveStaging';
        let params = formData.personnelList;
        if (this.formConfig.code === 'edit') {
          url = '/sfa/sfaMdmUserController/updateStaging';
        }

        if (e === 2) {
          url = '/sfa/sfaMdmUserController/save';
          params = formData.personnelList;
          if (this.formConfig.code === 'edit') {
            url = '/sfa/sfaMdmUserController/update';
          }
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
          // this.$emit('submit', {
          //   row: params,
          //   submitUrl: '/sfa/sfaMdmUserController/save',
          // });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
