<template>
  <div class="container">
    <!-- 明细信息 -->
    <div class="custom-table">
      <div v-if="!disabled">
        <el-button type="text" icon="el-icon-circle-plus-outline" @click="open">新增行</el-button>
        <el-button slot="reference" type="text"><i class="el-icon-delete" style="color: #f5222d" @click="clearAll">清空</i></el-button>
      </div>
      <vxe-table border show-overflow resizable ref="xTable" :data="detailsTable" :radio-config="{strict: false}" align="center" max-height="400">
        <vxe-table-column width="150" v-if="urlParam==='ExternalEmployees'">
           <template #header>
            <span ><span style="color: #F56C6C;margin-right: 4px;">*</span>变更类型</span>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.sfaCheckTypeDesc" :disabled="true"></el-input>
          </template>
        </vxe-table-column>
        <vxe-table-column v-for="(item,index) in columns" :key="index" :field="item.field" :title="item.label" :width="item.width||200">
          <template #header v-if="item.required">
            <span ><span style="color: #F56C6C;margin-right: 4px;">*</span>{{item.label}}</span>
          </template>
          <template slot-scope="scope">
            <div class="">
              <!-- v-model="value[scope.$rowIndex][item.field]" -->
              <el-input v-if="item.type === 'input'" v-model="scope.row[item.field]" :placeholder="`请输入${item.label}`"
              :disabled="isDisabled(item,scope)" :maxlength="item.max||9999" @change="changeValue"
              ></el-input>

              <el-input-number v-if="item.type === 'inputNumber'" v-model="scope.row[item.field]" :controls="item.controls"
              :placeholder="`请输入${item.label}`" :disabled="isDisabled(item,scope)" :min="0"
              :precision="item.precision || 0" @change="(val)=>detailInputChange(val,scope,item.field)">
              </el-input-number>
              <!-- 下拉框 -->
              <el-select v-if="item.type === 'select'" v-model="scope.row[item.field]" @change="(val)=>selectChange(val,item.field,scope)"
                :multiple="item.multiple" :collapse-tags="item.collapseTags"
                :placeholder="`请选择${item.label}`" @focus="(val)=>getSeleteOption(val,item.field,scope,'')"
                :remote-method="(name)=>{getSeleteOption(val,item.field,scope,name)}" :disabled="isDisabled(item,scope)" clearable filterable remote>
                <el-option v-for="(op,key) in scope.row[`${item.field}List`]" :key="key" :label="op.label" :value="op.value"></el-option>
              </el-select>
              <el-date-picker v-if="item.type === 'DatePicker'" v-model="scope.row[item.field]" :placeholder="`请选择日期`"
              :default-time="item.defaultTime" :disabled="isDisabled(item,scope)" @change="changeValue" type="datetime"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              <div class="customer" v-if="item.type === 'customer'">
                  <el-tooltip :content="scope.row.ext13" placement="top" v-if="scope.row.ext13">
                    <div class="customer-text" @click="openModal(scope)">
                        {{ scope.row.ext13}}
                    </div>
                  </el-tooltip>
                <div @click="openModal(scope)" class="open-modal" v-else>
                  请选择所属经销商
                </div>
              </div>
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column v-if="formRole!=='update2'" title="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="setting-btn">
              <el-button slot="reference" type="text" v-if="scope.row.imageDisabled">
                <i class="colorBlue" style="margin-right:20px;font-style: normal;" @click="openAnnex(scope)">{{code==='add'?'上传附件':'查看附件'}}</i>
              </el-button>
              <Popconfirm
                title="此操作将永久删除该数据？"
                confirm-button-type="text"
                @confirm="removeRow(scope.row)"
                v-if="!disabled"
              >
                <el-button slot="reference" type="text">
                  <i class="el-icon-delete" style="color: #f5222d"></i>
                </el-button>
              </Popconfirm>
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column v-if="formRole==='update2'" title="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="setting-btn">
              <el-button slot="reference" type="text">
                  <i class="colorBlue" style="margin-right:20px;font-style: normal;" v-if="scope.row.imageDisabled" @click="openAnnex(scope)">查看附件</i>
                  <i :class="scope.row.checkStatus!=='PASS'?'colorBlue':'colorGray'" style="margin-right:20px;font-style: normal;" @click="removeRow(scope.row,'PASS')">通过</i>
                  <i :class="scope.row.checkStatus!=='REJECT'?'colorRed':'colorGray'" style="font-style: normal;" @click="removeRow(scope.row,'REJECT')">驳回</i>
              </el-button>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <SelectConfig ref="SelectConfig" @onGetSelect="onGetSelect" />
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      @onClose="closeModal"
    >
      <component
        ref="modalForm"
        :is="formName"
        :formConfig="formConfig"
        @getResourceList="getResourceList"
        @onClose="closeModal"
      />
      <!-- <Material ref="modalForm" :formConfig="formConfig" @setFielValue="setFielValue" /> -->
      <!-- <div class="dialog-footer">
        <el-button type="danger" @click="confirmResource">确定</el-button>
        <el-button icon="el-icon-close" type="danger" @click="closeModal"
          >关闭</el-button
        >
      </div> -->
    </Modal>
  </div>

</template>

<script>
import { Popconfirm } from 'element-ui';
import { log } from 'mathjs';
import request from '../../../../../../utils/request';
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';
import Modal from '../../../../../../components/modal';
import Material from './material.vue';

const columns = [
  {
    label: '姓名', field: 'fullName', disabled: false, type: 'input', required: true, width: '150',
  },
  {
    label: '职位名称', field: 'positionLevelCode', disabled: false, type: 'select', option: [], required: true,
  },
  {
    label: '身份证号', field: 'identityCardNumber', disabled: false, type: 'input', required: true, width: '300', max: 23,
  },
  {
    label: '所属经销商', field: 'ext12', disabled: false, type: 'customer', option: [], required: true,
  },
  {
    label: '手机号码', field: 'userPhone', disabled: false, type: 'input', controls: false, required: true, max: 13,
  },
  {
    label: '上级名称', field: 'parentCode', disabled: false, type: 'select', option: [],
  },
  {
    label: '性别', field: 'gender', disabled: false, type: 'select', required: true, width: '150',
  },
  {
    label: '生效时间', field: 'startTime', disabled: false, type: 'DatePicker', defaultTime: '00:00:00', required: true, width: '250',
  },
  {
    label: '失效时间', field: 'endTime', disabled: false, type: 'DatePicker', defaultTime: '23:59:59', required: true, width: '250',
  },
  {
    label: '状态', field: 'enableStatus', disabled: false, type: 'select', required: true, width: '150',
  },
];
export default {
  name: 'editTable',
  components: {
    Popconfirm,
    SelectConfig,
    Modal,
    Material,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    getRule: Function,
    disableds: Boolean,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    formRole: String,
    code: String,
    urlParam: String,
  },
  watch: {
    value: {
      // deep: true,
      // immediate: true,
      handler(v) {
        console.log(v);
        this.detailsTable = v;
        this.getOption();
        // this.$nextTick(() => {
        //   this.$refs.xTable.setRadioRow(this.detailsTable[this.rowIndex]);
        // });
      },
    },
    formRole(newVal) {
      this.formRole = newVal;
    },
    code(newVal) {
      this.code = newVal;
    },
    urlParam(newVal) {
      this.urlParam = newVal;
    },
  },
  data() {
    return {
      // 明细信息行信息
      columns,
      detailsTable: [],
      zoneList: [],
      rowIndex: '', // 选中明细行的索引
      genderList: [], // 性别list
      customerList: [], // 所属经销商list
      userNameList: [], // 上级list
      positionLevelCodeList: [], // 职位list
      enableStatusList: [], // 状态list
      isBlue: true,
      isRed: true,
      sfaAttachUrls: [],
      modalConfig: {
        width: '60%',
        title: '上传附件',
        type: 'Modal',
        visible: false,
      },
      formConfig: {},
      formName: 'Material',
    };
  },
  created() {
    this.getDicts();
  },
  methods: {
    getOption() {
      this.detailsTable.map((v, index) => {
        if (!v.userNameList || (v.userNameList && v.userNameList.length === 0)) {
          request.post('/mdm/mdmUserExtController/sfaFindUserParentPositionSelectList', { selectedCode: v.parentCode, enableStatus: '009' }).then((res) => {
            if (res.success) {
              const { result } = res;
              const userNameList = result.map((a) => ({
                label: a.unionName, value: a.positionCode, userName: a.userName, parentCode: a.positionCode, parentName: a.positionName, orgCode: a.orgCode, parentFullName: a.fullName,
              }));
              this.$set(this.detailsTable[index], 'parentCodeList', userNameList);
              this.$set(this.detailsTable[index], 'userNameList', userNameList);
            }
          });
        }
        return v;
      });
    },
    // 上传附件
    openAnnex(scope) {
      this.formName = 'Material';
      const { row } = scope;
      this.dIndex = scope.$rowIndex;
      this.modalConfig = {
        width: '60%',
        title: '上传附件',
        type: 'Modal',
        visible: true,
      };
      this.formConfig = {
        row,
        code: this.code,
      };
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
    },
    // 获取上传附件
    getResourceList(val) {
      this.sfaAttachUrls = val;
      this.$set(this.detailsTable[this.dIndex], 'sfaAttachUrls', this.sfaAttachUrls);
      this.emitChange();
      this.closeModal();
    },
    // 判断字段是否禁用
    isDisabled(item, scope) {
      const fieldDisabled = false;
      return item.disabled || this.disabled || scope.row[`${item.field}Disabled`] || fieldDisabled;
    },
    // 向上传参
    emitChange() {
      console.log(234324, this.detailsTable);
      // this.$emit('change', this.detailsTable);
      this.$emit('input', this.detailsTable);
    },
    // 监听某些输入框和下拉框向上传值
    changeValue(val, field, scope) {
      // console.log('val, field, scope', val, field, scope);
      this.emitChange();
    },
    // 明细行输入框改变
    detailInputChange(val, scope, field) {
      const rowIndex = scope.$rowIndex;
      this.emitChange();
    },
    // 清空所有数据
    clearAll() {
      this.$confirm('确定要清空明细数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.detailsTable = [];
        this.rowIndex = '';
        this.emitChange();
      }).catch(() => {});
    },
    // 批量获取数据字典
    getDicts() {
      request.post('/mdm/mdmdictdata/batchDictSelect',
        ['gender', 'enable_status']).then((res) => {
        if (res.success) {
          const { result } = res;
          this.genderList = (result.gender || []).map((a) => ({ label: a.dictValue, value: a.dictCode }));
          this.enableStatusList = (result.enable_status || []).map((a) => ({ label: a.dictValue, value: a.dictCode }));
        }
      });
    },
    // 弹框选中
    onGetSelect(val) {
      console.log('弹框选中的数据----', val);
      this.$set(this.detailsTable[this.dIndex], 'ext12', val[0].customerCode);
      this.$set(this.detailsTable[this.dIndex], 'customerList', val);
      this.$set(this.detailsTable[this.dIndex], 'ext13', val[0].customerName);
      this.emitChange();
    },
    // 选择所属经销商
    openModal(scope) {
      if (this.disabled) return false;
      const { row } = scope;
      this.dIndex = scope.$rowIndex;
      const params = {
        data: row.customerList || [],
        selectionList: [],
        functionCode: 'dealer_list',
        idKey: 'customerCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
          actApproveStatus: 3,
        },
      };
      this.$refs.SelectConfig.openSelectModal(params);
    },
    // 下拉改变
    selectChange(val, field, scope) {
      const row = this.detailsTable[scope.$rowIndex];
      if (field === 'parentCode') {
        const userNameList = row.userNameList.find((v) => (v.value === val));
        const unionName = userNameList.label;
        const { userName } = userNameList;
        const parentFullName = userNameList.fullName;
        const { parentCode } = userNameList;
        const { parentName } = userNameList;
        const { orgCode } = userNameList;
        this.$set(this.detailsTable[scope.$rowIndex], 'unionName', unionName);
        this.$set(this.detailsTable[scope.$rowIndex], 'parentFullName', parentFullName);
        this.$set(this.detailsTable[scope.$rowIndex], 'parentCode', parentCode);
        this.$set(this.detailsTable[scope.$rowIndex], 'parentName', parentName);
        this.$set(this.detailsTable[scope.$rowIndex], 'orgCode', orgCode);
        this.$set(this.detailsTable[scope.$rowIndex], 'userName', userName);
      }
      if (field === 'positionLevelCode') {
        if (val === 'ZWJB000283') {
          this.$set(this.detailsTable[scope.$rowIndex], 'imageDisabled', false);
        } else {
          this.$set(this.detailsTable[scope.$rowIndex], 'imageDisabled', true);
        }
        const positionLevelName = row.positionLevelCodeList.find((v) => (v.value === val)).label;
        this.$set(this.detailsTable[scope.$rowIndex], 'positionLevelName', positionLevelName);
      }
      if (field === 'gender') {
        const genderName = row.genderList.find((v) => (v.value === val)).label;
        this.$set(this.detailsTable[scope.$rowIndex], 'genderName', genderName);
      }
      if (field === 'enableStatus') {
        const enableStatusName = row.enableStatusList.find((v) => (v.value === val)).label;
        this.$set(this.detailsTable[scope.$rowIndex], 'enableStatusName', enableStatusName);
      }
      this.emitChange();
    },
    // 获取下拉框选项
    getSeleteOption(val, field, scope, name) {
      const row = this.detailsTable[scope.$rowIndex];
      if (field === 'ext12') {
        this.$set(this.detailsTable[scope.$rowIndex], field, val);
      }
      if (field === 'parentCode') {
        const params = {
          // fullName: row.parentFullName || '',
          unionName: name,
        };
        this.getUserName(params, scope);
      }
      if (field === 'positionLevelCode') {
        const params = {
          // positionLevelName: row.positionLevelName || '',
          positionLevelName: name,
        };
        this.getPositionCode(params, scope);
      }
      this.emitChange();
    },
    // 获取上级
    getUserName(params, scope) {
      request.post('/mdm/mdmUserExtController/sfaFindUserParentPositionSelectList', { ...params, enableStatus: '009' }).then((res) => {
        if (res.success) {
          const { result } = res;
          const userNameList = result.map((a) => ({
            label: a.unionName, value: a.positionCode, userName: a.userName, parentCode: a.positionCode, parentName: a.positionName, orgCode: a.orgCode, parentFullName: a.fullName,
          }));
          this.$set(this.detailsTable[scope.$rowIndex], 'userNameList', userNameList);

          this.$set(this.detailsTable[scope.$rowIndex], 'parentCodeList', userNameList);
        }
      });
    },
    // 获取职位
    getPositionCode(params, scope) {
      request.post('/mdm/mdmPositionExtController/sfaSelectList', { ...params, enableStatus: '009' }).then((res) => {
        if (res.success) {
          const { result } = res;
          const positionLevelCodeList = result.map((a) => ({ label: a.positionLevelName, value: a.positionLevelCode }));
          this.$set(this.detailsTable[scope.$rowIndex], 'positionLevelCodeList', positionLevelCodeList);
        }
      });
    },
    // 新增明细行
    open() {
      const {
        genderList,
        customerList,
        userNameList,
        positionLevelCodeList,
        enableStatusList,
      } = this;
      this.detailsTable.push({
        ...{
          genderList,
          customerList,
          userNameList,
          positionLevelCodeList,
          enableStatusList,
          enableStatus: '009',
        },
      });
      this.emitChange();
    },
    // 删除行数据
    removeRow(row, type) {
      console.log(row, type);
      if (this.formRole === 'update2') {
        if (type === 'PASS') {
          const params = {
            ids: [row.id], formNo: row.processCode, checkStatus: 'PASS', userName: row.userName,
          };
          request.post('/sfa/sfaMdmUserController/approveProcess', params).then((res) => {
            if (res.success) {
              const index = this.$refs.xTable.getRowIndex(row);
              this.detailsTable[index].checkStatus = 'PASS';
              this.isBlue = false;
              this.isRed = true;
            }
          });
        } else if (type === 'REJECT') {
          const params = {
            ids: [row.id], formNo: row.processCode, checkStatus: 'REJECT', userName: row.userName,
          };
          request.post('/sfa/sfaMdmUserController/approveProcess', params).then((res) => {
            if (res.success) {
              const index = this.$refs.xTable.getRowIndex(row);
              this.detailsTable[index].checkStatus = 'REJECT';
              this.isBlue = true;
              this.isRed = false;
            }
          });
        }
      } else {
        const index = this.$refs.xTable.getRowIndex(row);
        this.detailsTable.splice(index, 1);
        if (this.rowIndex === index) {
          this.rowIndex = '';
        }
      }
      this.emitChange();
    },
  },
};
</script>

<style lang="less" scoped>
.custom-table {
  padding-left: 30px;
  box-sizing: border-box;
}
.title{
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 22px 0;
}
.open-modal{
  color: #69a6f8;
  cursor: pointer;
}
.customer-text{
  color: #69a6f8;
  cursor: pointer;
  width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.customer{
  display: flex;
  justify-content: center;
}
.colorBlue{
  color: #006eff;
}
.colorRed{
  color: #f5222d;
}
.colorGray{
  color: #727272;
}
</style>
