<script>
import Form, { formCreate } from '../../../../../../components/form';

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {

    };
  },
  props: {
    formConfig: Object,
  },
  async created() {
    const rule = await this.getFormRule('upload_attachments', 'CRM20230313000000002');
    const _this = this;
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'sfaAttachUrls') {
        v.props = {
          ...v.props,
          limit: 999,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
        };
      }
      if (this.formConfig.code === 'view') {
        v.props = {
          ...v.props,
          allowRemove: false, // 查看时不允许移除图片
          disabled: true,
        };
        this.buttons.submit = false;
      }
    });
    this.rule = rule;
    this.reload(this.rule);
  },
  methods: {
    formComplete() {
      this.setValue({
        sfaAttachUrls: this.formConfig.row.sfaAttachUrls,
      });
    },
    submit() {
      const formData = this.getFormData();
      this.$emit('getResourceList', formData.sfaAttachUrls);
    },
    // 关闭弹窗
    closeModal() {
      this.$emit('onClose');
    },
  },
};
</script>
